import { createContext, useContext, useEffect, useState } from "react";
import { createBrowserClient } from "@supabase/auth-helpers-remix";
import type { SupabaseClient, User } from "@supabase/supabase-js";

type SupabaseContext = {
	supabase: SupabaseClient;
	user: User | null;
};

export const Context = createContext<SupabaseContext | undefined>(undefined);

export function SupabaseProvider({
	children,
	env,
}: {
	children: React.ReactNode;
	env: {
		SUPABASE_URL: string;
		SUPABASE_ANON_KEY: string;
	};
}) {
	const [supabase] = useState(() =>
		createBrowserClient(env.SUPABASE_URL, env.SUPABASE_ANON_KEY),
	);

	const [user, setUser] = useState<User | null>(null);

	useEffect(() => {
		const {
			data: { subscription },
		} = supabase.auth.onAuthStateChange((_event, session) => {
			setUser(session?.user ?? null);
		});

		return () => {
			subscription.unsubscribe();
		};
	}, [supabase]);

	return (
		<Context.Provider value={{ supabase, user }}>{children}</Context.Provider>
	);
}

export function useSupabase() {
	const context = useContext(Context);
	if (context === undefined) {
		throw new Error("useSupabase must be used within a SupabaseProvider");
	}
	return context;
}
